/* eslint-disable max-classes-per-file */
/* eslint-env browser */
import queryString from "qs";

class Enrollment {
  constructor(enrollment) {
    Object.assign(this, enrollment);
  }
}

/* @ngInject */
export default class EnrollmentService {
  constructor(backendConfig, $http, Authentication, $injector) {
    this._backendConfig = backendConfig;
    this._features = this._backendConfig.features;
    this._$http = $http;
    this._Authentication = Authentication;
    this._$window = $injector.get("$window");
    this.data = {};
  }

  /**
   * @returns {Promise<Enrollment[]>} Array of all enrollments
   *
   * @see SRS: BR-1128
   */
  getAll() {
    const url = "/enrollments";
    return this.httpGet(url).then(
      (response) => {
        this.enrollments = response.data.map((enrollment) => {
          return new Enrollment(enrollment);
        });
        return this.enrollments;
      },
      (error) => {
        throw error;
      }
    );
  }

  /**
   * @param {Object} params - properties to limit getEnrollment request by
   * @returns {Promise<Object[]>} enrollments
   *
   * @see SRS: BR-1128
   */
  getEnrollments(params = {}) {
    const url = "/enrollments";
    return this.httpGet(url, params).then(
      (response) => {
        this.enrollments = response.data.map((enrollment) => {
          return new Enrollment(enrollment);
        });
        return this.enrollments;
      },
      (error) => {
        throw error;
      }
    );
  }

  /**
   * @param {String} enrollmentId - enrollment to get with siblings
   * @param {Object} params - properties to limit request by
   * @returns {Promise<Object[]>} enrollments
   *
   * @see SRS: BR-2785
   */
  getEnrollmentAndSiblings(enrollmentId, params = {}) {
    const url = "/enrollments";
    return this.httpGet(url, {...params, id: enrollmentId})
      .then(
        (response) => {
          const enrollment = response.data[0];
          if (enrollment?.studyId) {
            return this.httpGet(url, {...params, studyId: enrollment.studyId});
          }
          return response;
        },
        (error) => {
          throw error;
        }
      )
      .then(
        (response) => {
          this.enrollments = response.data.map((enrollment) => {
            return new Enrollment(enrollment);
          });
          return this.enrollments;
        },
        (error) => {
          throw error;
        }
      );
  }

  /**
   * Gets holter data for device enrollment and downloads it
   *
   * @param {String} enrollmentId
   * @param {Object} params
   * @returns {Promise}
   *
   * @see SRS: BR-3185
   */
  getHolterData(enrollmentId, params) {
    const url = `/enrollments/export/${enrollmentId}`;

    return this.httpGet(url, params).then((response) => {
      const zipUrl = response.data;

      if (zipUrl) {
        this._$window.open(zipUrl, "_blank");
      } else {
        throw new Error("No Url Found");
      }
    });
  }

  /**
   * @param {string} url   - the url to make a request to
   * @param {Object} params - the query parameters to attach to the request
   * @returns {Promise} get request
   * @see SRS: BR-1128
   */
  httpGet(url, params) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  /**
   * @param {string} url   - the url to make a request to
   * @param {Object} data  - the data to replace in patch Request
   * @returns {Promise} PATCH request
   * @see SRS: BR-1128
   */
  httpPatch(url, data) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.patch(`${baseUrl}${url}`, data, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
